<template>
    <div class="input-holder-checkbox">
        <div
            ref="formSwitch"
            class="form-check form-switch"
        >
            <input
                v-bind="options"
                :id="id"
                v-model="changed"
                :data-selector-value="dataTestValue"
                type="checkbox"
                class="form-check-input"
                @change="inputHandler"
            >
        </div>
        <label
            v-if="!empty(label)"
            v-html="label"
        />
    </div>
</template>

<script>
import { empty } from '@/utils/functions';
import SplInputMixin from '../input-mixin';

export default {
    name: 'XInputCheckbox',
    mixins: [SplInputMixin],
    props: {
        options: {
            type: Object,
            default() {
                return {};
            },
        },
        // For v-model
        value: {
            default: false,
            type: [Boolean, String, Number],
        },
        name: {
            default: 'name',
            type: String,
        },
        id: {
            default: 'id',
            type: String,
        },
        label: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            changed: !!JSON.parse(this.value),
        };
    },
    computed: {
        dataTestValue() {
            return this.changed ? 'on' : 'off';
        },
    },
    watch: {
        value(newValue) {
            this.changed = !!JSON.parse(newValue);
        },
    },
    methods: {
        empty,
        inputHandler() {
            this.$emit('input', !!JSON.parse(this.changed));
        },
    },
};
</script>
