<template>
    <transition :name="transition">
        <div
            v-if="visible"
            ref="dropdown"
            :class="className"
            :style="style"
            :data-popover="name"
            @click.stop
        >
            <slot />
        </div>
    </transition>
</template>

<script>
import { subscription } from 'vue-js-popover/src/subscription';
import { getFixedPositionParents, getMaxZIndex } from 'vue-js-popover/src/utils';
import { directive } from 'vue-js-popover/src/directive';
import Vue from 'vue';
import { empty, isset } from '@/utils/functions';

const pointerSize = 6;

// const anchors = {
//     leftTop: '',
//     leftCenter: '',
//     leftBottom: '',
//     topLeft: '',
//     topCenter: '',
//     topRight: '',
//     rightTop: '',
//     rightCenter: '',
//     rightBottom: '',
//     bottomLeft: '',
//     bottomCenter: '',
//     bottomRight: ''
// }

const directions = {
    left: [-1, 0],
    right: [1, 0],
    top: [0, 1],
    bottom: [0, -1],
};

export default {
    name: 'XPopover',
    props: {
        name: {
            type: String,
            required: true,
        },
        delay: {
            type: Number,
            default: 0,
        },
        transition: {
            type: String,
        },
        width: {
            type: Number,
            default: 180,
        },
        pointer: {
            type: Boolean,
            default: true,
        },
        event: {
            type: String,
            default: 'click',
        },
        anchor: {
            type: Number,
            default: 0.5,
            validator: (v) => v >= 0 && v <= 1,
        },
        notHideOnContent: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            visible: false,
            timeout: null,
            zIndex: 1,
            positionClass: '',
            fixedParents: [],
            position: {
                left: 0,
                top: 0,
            },
            dialogBody: null,
        };
    },
    computed: {
        showEventName() {
            return `show:${this.event}`;
        },
        hideEventName() {
            return `hide:${this.event}`;
        },
        className() {
            return ['vue-popover', this.pointer && this.positionClass];
        },
        style() {
            const { width, zIndex, fixedParents } = this;
            const hasFixedParents = fixedParents.length > 0;

            const styles = {
                width: `${width}px`,
                zIndex,
                ...this.position,
            };

            if (hasFixedParents) {
                styles.position = 'fixed';
            }

            return styles;
        },
        isCheckHideOnContent() {
            return this.notHideOnContent && this.event === 'hover';
        },
    },
    mounted() {
        subscription.$on(this.showEventName, this.showEventListener);
        subscription.$on(`${this.hideEventName}-no-delay`, ($event) => this.hideEventListener($event, true));
        subscription.$on(this.hideEventName, ($event) => this.hideEventListener($event, false));
        this.dialogBody = document.querySelector('.vue-dialog-body');
        if (!empty(this.dialogBody)) {
            this.dialogBody.addEventListener('scroll', this.scrollEventListener);
        }
    },
    beforeDestroy() {
        subscription.$off(this.showEventName, this.showEventListener);
        subscription.$off(this.hideEventName, this.hideEventListener);
        if (!empty(this.dialogBody)) {
            this.dialogBody.removeEventListener('scroll', this.scrollEventListener);
        }
    },
    methods: {
        scrollEventListener() {
            this.showEventListener();
        },
        waitElementRemoved(parent, selector) {
            return new Promise((resolve) => {
                if (!$(selector).length) {
                    resolve(true);
                }
                const observer = new MutationObserver(() => {
                    if (!$(selector).length) {
                        resolve(true);
                        observer.disconnect();
                    }
                });
                observer.observe(parent, {
                    childList: true,
                    subtree: true,
                });
            });
        },
        showEventListener(event) {
            this.waitElementRemoved(document.body, 'div.vue-popover').then(() => {
                if (this.visible || typeof event === 'undefined') {
                    subscription.$emit(this.hideEventName);
                    return;
                }

                let { name, position } = event;

                if (name !== this.name) {
                    return;
                }

                this.timeout = setTimeout(() => {
                    this.positionClass = `dropdown-position-${position}`;

                    this.visible = true;

                    this.$nextTick(() => {
                        this.$emit('show', event);

                        this.$nextTick(() => {
                            let position = this.getDropdownPosition(event);

                            this.position = {
                                left: `${position.left}px`,
                                top: `${position.top}px`,
                            };
                        });
                    });
                }, Math.max(this.delay, 0));
            });
        },

        hideEventListener(event, noDelay) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            let { isCheckHideOnContent } = this;
            if (isset(event, ['notCheckContent']) && event.notCheckContent) {
                isCheckHideOnContent = false;
            }

            if (noDelay) {
                this.positionClass = '';
                this.visible = false;

                this.$nextTick(() => {
                    this.$emit('hide', event);
                });
            }

            this.timeout = setTimeout(() => {
                // Cursor is still on popover content
                if (isCheckHideOnContent && $('div.x-tooltip-content:hover').length !== 0) {
                    return;
                }

                this.positionClass = '';
                this.visible = false;

                this.$nextTick(() => {
                    this.$emit('hide', event);
                });
            }, Math.max(this.delay, 0));
        },

        getDropdownPosition(event) {
            const { target, position } = event;

            const direction = directions[position];
            const { dropdown } = this.$refs;

            const trRect = target.getBoundingClientRect();
            const ddRect = dropdown.getBoundingClientRect();

            this.fixedParents = getFixedPositionParents(target);

            const zIndex = event.zIndex
                ? event.zIndex
                : getMaxZIndex([target, ...this.fixedParents]) + 1;

            this.zIndex = zIndex;

            let offsetLeft = trRect.left;
            let offsetTop = trRect.top;

            if (this.fixedParents.length === 0) {
                // Scroll offset of the current document
                const scrollTop = window.pageYOffset
                    || document.documentElement.scrollTop
                    || document.body.scrollTop;

                const scrollLeft = window.pageXOffset
                    || document.documentElement.scrollLeft
                    || document.body.scrollLeft;

                // Position within the parent
                offsetLeft = trRect.left + scrollLeft;
                offsetTop = trRect.top + scrollTop;
            }

            // let shiftX = ddRect.width - trRect.width
            let shiftY = 0.5 * (ddRect.height + trRect.height);

            // Center of the target element
            let centerX = offsetLeft - 0.5 * (ddRect.width - trRect.width);
            let centerY = offsetTop + trRect.height - shiftY;

            // let anchorX = direction[0] * this.anchor
            // let anchorY = direction[0] * this.anchor

            // Position of the dropdown relatively to target
            let x = direction[0] * 0.5 * (ddRect.width + trRect.width);
            let y = direction[1] * shiftY;

            // Pointer size correction
            if (this.pointer) {
                x += direction[0] * pointerSize;
                y += direction[1] * pointerSize;
            }

            return {
                left: Math.round(centerX + x) > 0
                    ? Math.round(centerX + x) : 0,
                top: Math.round(centerY - y),
            };
        },
    },
};
Vue.directive('x-popover', directive());
</script>
