<template>
    <form
        ref="form"
        :method="method"
        :action="url"
        role="form"
    >
        <slot />
    </form>
</template>

<script>
export default {
    name: 'XForm',
    props: {
        url: String,
        method: {
            type: String,
            default: 'POST',
        },
        disableConfirmation: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default() {
                return {};
            },
        },
        preventDb: String,
        data: {
            type: [Object, FormData],
        },
        className: {
            type: String,
            default: '',
        },
        attributesMeta: {
            type: Object,
        },
        afAttributesMeta: {
            type: [Object, Array],
        },
        beforeSend: {
            type: Function,
        },
        beforeSendAdditional: {
            type: Function,
        },
        formDataSend: {
            type: Boolean,
            default: false,
        },
        checkUnsavedDataBeforeLeavePage: {
            type: Boolean,
            default: true,
        },
        notifyOnInvalid: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isProcessing: false,
        };
    },
    watch: {
        isProcessing(newVal) {
            let buttons;
            if (empty(this.preventDb)) {
                buttons = $(this.$el).find('button');
            } else {
                buttons = $(this.preventDb);
            }

            if (empty(buttons) || buttons.length === 0) {
                return;
            }

            if (newVal) {
                buttons.attr('disabled', 'disabled');
            } else {
                buttons.removeAttr('disabled');
            }
        },
    },
    mounted() {
        this.validateInputs();
        setTimeout(() => {
            // Delay for additional fields mount waiting
            this.validateInputs();
        }, 1000);

        if (this.checkUnsavedDataBeforeLeavePage) {
            setTimeout(() => {
                $(this.$refs.form).on(
                    'change',
                    'input:enabled:not([data-silent-input]),'
                    + 'select:enabled:not([data-silent-input]),'
                    + 'textarea:enabled:not([data-silent-input])',
                    () => {
                        $(this.$refs.form).attr('data-form-changed', '1');
                    },
                );
            }, 4000);
        }
    },
    methods: {
        submit() {
            if (this.isProcessing) {
                return;
            }

            this.$emit('submit');

            this.isProcessing = true;
            if (this.$refs.form.reportValidity()) {
                let dataToSend = this.getChangedValuesToSend();

                if (typeof this.beforeSend === 'function') {
                    dataToSend = this.beforeSend(dataToSend);
                    if (dataToSend === false) {
                        this.isProcessing = false;
                        return;
                    }
                }

                let formData = new FormData();
                if (dataToSend === null || (typeof dataToSend === 'object' && Object.keys(dataToSend).length === 0)) {
                    // Add empty Model[] to FormData for correct working of Model->load method on backend
                    let className = this.getOnlyClassName();
                    formData.append(`${className}[]`, '');
                }

                if (dataToSend instanceof FormData) {
                    formData = dataToSend;
                } else {
                    this.objectToFormData(formData, dataToSend, this.className);
                }

                let entries = Array.from(formData.entries());
                if (entries.length === 0) {
                    // Add empty Model[] to FormData for correct working of Model->load method on backend
                    formData.append(`${this.className}[]`, '');
                }

                if (typeof this.beforeSendAdditional === 'function') {
                    formData = this.beforeSendAdditional(formData);
                }

                $.ajax({
                    url: this.url,
                    method: this.method,
                    data: formData,
                    contentType: false,
                    processData: false,
                    success: (response) => {
                        if (response.result === undefined || response.result !== false) {
                            this.changeDefaultValues(dataToSend);
                            $(this.$refs.form).removeAttr('data-form-changed');
                        }

                        this.$emit('success', response);
                    },
                    error: (error) => {
                        this.$emit('error', error);
                    },
                    complete: () => {
                        this.isProcessing = false;
                    },
                });
            } else {
                this.isProcessing = false;
            }
        },
        // Return only class name without parameters.
        // For example this.className is 'Config[params]', so we return only 'Config'
        getOnlyClassName() {
            return this.className.split('[')[0];
        },
        changeDefaultValues(data, attributes = this.attributesMeta, property = 'default') {
            if (this.attributesMeta === undefined) {
                return;
            }

            Object.entries(data).forEach(([key, value]) => {
                if (key.indexOf('additional_attributes') !== -1) {
                    this.changeDefaultValues(value, this.afAttributesMeta, 'value');
                } else if (isset(attributes, key)) {
                    attributes[key][property] = this.prepareValueToSet(value, attributes[key]);
                }
            });
        },
        objectToFormData(formData, data, parentKey) {
            if (data === null || data === undefined) {
                return;
            }

            if (typeof data === 'object'
                && !(data instanceof Date)
                && !(data instanceof File)
                && !Array.isArray(data)
            ) {
                Object.keys(data).forEach((key) => {
                    let nestedKey = parentKey ? `${parentKey}[${key}]` : key;
                    this.objectToFormData(formData, data[key], nestedKey);
                });
            } else if (Array.isArray(data)) {
                formData.append(parentKey, '');
                for (let key of data.keys()) {
                    if (typeof data[key] !== 'object') {
                        formData.append(`${parentKey}[]`, data[key]);
                        continue;
                    }

                    // hack for ip additional fields
                    // todo: try to move this logic out
                    if (Object.keys(data[key]).length === 2 && isset(data[key], 'id') && isset(data[key], 'text')) {
                        formData.append(`${parentKey}[]`, data[key].id);
                        continue;
                    }

                    this.objectToFormData(formData, data[key], `${parentKey}[${key}]`);
                }
            } else {
                formData.append(parentKey, data);
            }
        },
        validateInputs() {
            // Fix error: An invalid form control with name='inputName' is not focusable.
            // removeRequiredFromHiddenInputs();
            // addRequiredToVisibleInputs();

            $(this.$refs.form).find('input,select,textarea').off('invalid', this.processInvalidInputs);
            $(this.$refs.form).find('input,select,textarea').on('invalid', this.processInvalidInputs);
            if (typeof window.splynx_event_bus !== 'undefined') {
                window.splynx_event_bus.off('invalid', this.processInvalidInputs);
                window.splynx_event_bus.on('invalid', this.processInvalidInputs);
            }
        },
        processInvalidInputs(e) {
            if (!$(this.$refs.form).get(0)?.contains(e.target)) {
                return;
            }
            let parentBlock = $(e.target).closest('.form-group');
            if (parentBlock.length <= 0) {
                parentBlock = $(e.target).parent();
            }

            if (this.notifyOnInvalid) {
                setTimeout(() => {
                    const message = $(e.target).attr('data-message');
                    const duplicateMessage = $(`.toaster-content > div:contains('${message}')`).length;
                    if (message && !duplicateMessage) {
                        window.show_error($(e.target).attr('data-message'), 4);
                    }
                }, 50);
            }
            parentBlock.addClass('has-error');

            $(e.target).off('change', this.processChangeInvalidInput);
            $(e.target).on('change', this.processChangeInvalidInput);
        },
        processChangeInvalidInput(e) {
            $(e.target).closest('.has-error').removeClass('has-error');
        },
        getChangedValuesToSend() {
            let toSend = {};

            if (this.formDataSend) {
                return this.data;
            }

            for (let attribute in this.data) {
                let value = this.data[attribute];

                if (attribute.indexOf('additional_attributes') !== -1) {
                    toSend[attribute] = {};
                    for (let afAttribute in value) {
                        let afMeta = this.afAttributesMeta[afAttribute];
                        let original = afMeta.value;
                        let isAjax = isset(afMeta, 'ajax') && afMeta.ajax;
                        let currentValue = value[afAttribute];

                        if (isAjax) {
                            if (Array.isArray(currentValue)) {
                                if (afMeta.type === 'relation_multiple') {
                                    let arr = [];
                                    currentValue.forEach((item) => {
                                        arr.push(item.id);
                                    });
                                    currentValue = arr;
                                } else {
                                    let first = currentValue[0];
                                    if (typeof first === 'object') {
                                        currentValue = first.id;
                                    } else {
                                        currentValue = first;
                                    }
                                }
                            } else if (typeof currentValue === 'object') {
                                currentValue = currentValue.id;
                            }
                        }

                        if (this.valueIsChanged(currentValue, original)) {
                            toSend[attribute][afAttribute] = this.prepareValueToSend(currentValue, afMeta);
                        }
                    }
                    continue;
                }

                let meta = this.attributesMeta[attribute];
                if (empty(meta)) {
                    console.warn(`Empty attribute meta: ${attribute}`);
                    toSend[attribute] = this.prepareValueToSend(value, null);
                    continue;
                }
                let originalValue = meta.default;

                if (meta.forceSend) {
                    toSend[attribute] = this.prepareValueToSend(value, meta);
                    continue;
                }

                if (this.valueIsChanged(value, originalValue)) {
                    toSend[attribute] = this.prepareValueToSend(value, meta);
                }
            }

            return toSend;
        },
        valueIsChanged(value, original) {
            if (value instanceof Array) {
                if (original instanceof Array) {
                    return !this.arraysIsEqual(value, original);
                }
                return value.length > 0;
            }
            if (($.isNumeric(original) && $.isNumeric(value)) || typeof value == 'boolean' || typeof original == 'boolean') {
                return value !== original;
            }
            return value !== original;
        },
        arraysIsEqual(arr1, arr2) {
            if (arr1.length !== arr2.length) {
                return false;
            }

            let arrFixed2 = arr2.map((x) => x.toString());

            for (let i = 0; i < arr1.length; i++) {
                if (!arrFixed2.includes(arr1[i].toString())) {
                    return false;
                }
            }

            return true;
        },
        getTypeFromMeta(meta) {
            if (isset(meta, ['rule', 'type'])) {
                return meta.rule.type;
            }

            if (isset(meta, ['type'])) {
                return meta.type;
            }

            return null;
        },
        prepareValueToSend(value, meta) {
            if (this.getTypeFromMeta(meta) === 'boolean') {
                return value != '0' && value ? '1' : '0';
            }

            return value;
        },
        prepareValueToSet(value, meta) {
            if (this.getTypeFromMeta(meta) === 'boolean') {
                return value ? 1 : 0;
            }

            return value;
        },
    },
};
</script>
