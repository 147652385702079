<template>
    <nav
        ref="menuHolder"
        class="side-wrap"
    >
        <x-menu-app
            ref="menu"
            :app-part="appPart"
            :menu-structure="menuStructure"
            :switch-page="switchPageUrl"
            :collapsed-menu="collapsedMenu"
        >
            <slot />
        </x-menu-app>
        <a
            v-if="showGoTop"
            :style="anchorStyle"
            class="btn btn-light btn-icon btn-to-top color-dark"
            :title="$t('common', 'Go to top')"
            @click="goTop"
        >
            <i class="icon-ic_fluent_arrow_up_24_regular" />
        </a>
    </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import XMenuApp from './XMenuApp';

export default {
    name: 'XMenu',
    props: {
        collapseFirst: {
            type: Boolean,
            default: false,
        },
        appPart: String,
        menuStructure: [Object, Array],
    },
    data() {
        return {
            switchPageUrl: '',
            showGoTop: false,
            windowHeight: 0,
            scrollTop: 0,
            anchorStyle: '',
        };
    },
    beforeMount() {
        if (this.collapseFirst) {
            this.toggleMenu(true);
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScrollState);
        this.handleScrollState();
        window.addEventListener('popstate', this.handlePopState);
        this.$router.afterEach(() => {
            this.handlePopState();
        });
    },
    destroyed() {
        window.addEventListener('scroll', this.handleScrollState);
        window.removeEventListener('popstate', this.handlePopState);
    },
    methods: {
        updateMenu(url) {
            this.switchPageUrl = url;
        },
        closeMobileMenu() {
            this.showMobileMenu(false);
        },
        handlePopState() {
            this.updateMenu(window.location.pathname);
        },
        handleScrollState() {
            this.windowHeight = document.documentElement.clientHeight;
            this.scrollTop = window.pageYOffset;
            this.showGoTop = this.windowHeight < this.scrollTop;
            this.anchorStyle = this.styleForAnchor;
        },
        ...mapActions('menu_store', [
            'toggleMenu',
            'showMobileMenu',
        ]),
        goTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        },
    },
    computed: {
        ...mapGetters('menu_store', [
            'mobileState',
            'collapseState',
        ]),
        mobileMenu() {
            return this.mobileState;
        },
        collapsedMenu() {
            return this.collapseState;
        },
        styleForAnchor() {
            return this.scrollTop + this.windowHeight > document.body.clientHeight - 31
                ? 'transform:translateY(calc(-100% + 12px))'
                : '';
        },
    },
    components: {
        XMenuApp,
    },
};
</script>
