<template>
    <div class="input-group">
        <button
            v-if="showPasswordButton"
            class="btn btn-outline-secondary btn-icon show-password"
            type="button"
            :title="buttonTitle"
            @click="togglePassword"
        >
            <i :class="buttonIcon" />
        </button>

        <input
            ref="passwordInput"
            data-dry-value=""
            type="text"
            :value="passwordSign"
            v-bind="attributesForVisible"
            autocomplete="off"
            :style="borderStyle"
            @copy="copyHandler"
            @paste="processKeyboardHandler"
            @cut="processKeyboardHandler"
            @undo="processKeyboardHandler"
            @keydown="processKeyboardHandler"
        >
        <input
            ref="hiddenInput"
            v-bind="attributesForHidden"
            type="hidden"
        >

        <button
            v-if="generatePassword"
            class="btn btn-outline-secondary btn-icon generate-password"
            type="button"
            :title="$t('common', 'Generate password')"
            @click="generatePasswordHandler()"
        >
            <i class="icon-ic_fluent_lock_shield_24_regular" />
        </button>
    </div>
</template>

<script>
import { isset, getNewInputValue, setCaretToPosition } from '@/utils/functions';
import SplInputMixin from '../input-mixin';

export default {
    name: 'XInputPassword',
    mixins: [SplInputMixin],
    props: {
        options: {
            type: Object,
            default: () => ({}),
        },
        value: [String, Number],
        showPasswordButton: {
            type: Boolean,
            default: true,
        },
        generatePassword: {
            type: Boolean,
            default: true,
        },
        showGenerateConfirm: {
            type: Boolean,
            default: true,
        },
        sign: {
            type: String,
            default: '*',
        },
        generatePasswordUrl: {
            type: String,
            default: '/admin/tools--generate-password',
        },
    },
    data() {
        return {
            dryValue: this.value || '',
            visible: false,
        };
    },
    mounted() {
        this.$refs.passwordInput.setAttribute('data-dry-value', this.value || '');
        this.$refs.hiddenInput.value = this.value || '';
    },
    computed: {
        passwordSign() {
            if (this.visible) {
                return this.dryValue || '';
            }
            return this.sign.repeat(this.dryValue.length);
        },
        attributesForVisible() {
            let attributes = { ...this.options };
            if (!isset(attributes, ['class'])) {
                attributes.class = '';
            }

            attributes.class = this.addClass(attributes.class, ['form-control', 'input-sm']);
            delete attributes.id;
            delete attributes.name;

            return attributes;
        },
        attributesForHidden() {
            let attributes = { ...this.$attrs };
            if (isset(this.options, 'name')) {
                attributes.name = this.options.name;
            }
            if (isset(this.options, 'id')) {
                attributes.id = this.options.id;
            }

            return attributes;
        },
        buttonTitle() {
            return this.visible ? this.$t('common', 'Hide password') : this.$t('common', 'Show password');
        },
        buttonIcon() {
            return this.visible ? 'icon-ic_fluent_eye_off_24_regular' : 'icon-ic_fluent_eye_24_regular';
        },
        borderStyle() {
            if (this.generatePassword) {
                return {};
            }
            return {
                'border-top-right-radius': '4px',
                'border-bottom-right-radius': '4px',
            };
        },
    },
    methods: {
        getNewInputValue,
        setCaretToPosition,
        emitInputChange() {
            $(this.$refs.passwordInput).trigger('change');
            $(this.$refs.hiddenInput).trigger('change');
        },
        processKeyboardHandler(event) {
            const newValue = this.getNewInputValue(event, this.visible);
            if (!newValue || newValue.value === null) {
                return true;
            }
            event.preventDefault();
            this.$refs.passwordInput.setAttribute('data-dry-value', newValue.value || '');
            this.$refs.hiddenInput.value = this.$refs.passwordInput.getAttribute('data-dry-value') || '';
            this.$emit('input', this.$refs.passwordInput.getAttribute('data-dry-value') || '');
            this.$nextTick(() => {
                this.setCaretToPosition(this.$refs.passwordInput, newValue.focusOn);
            });
        },
        copyHandler(event) {
            if (this.visible) {
                return true;
            }
            event.preventDefault();
            return false;
        },
        togglePassword() {
            this.visible = !this.visible;
            this.$emit('input', this.dryValue);
        },
        generatePasswordHandler() {
            if (!this.generatePassword) {
                return;
            }

            if (this.showGenerateConfirm && this.value !== '' && !window.confirm(t('config', 'Are you sure you want to generate a random password?'))) {
                return;
            }

            $.ajax({
                type: 'GET',
                url: this.generatePasswordUrl,
                dataType: 'json',
                success: (response) => {
                    this.$emit('input', response.string);
                    this.$emit('new-generate-password', response.string);
                    this.emitInputChange();
                    this.visible = true;
                },
            });
        },
    },
    watch: {
        value(newValue) {
            this.dryValue = newValue || '';
        },
        dryValue(newValue) {
            this.$refs.passwordInput.setAttribute('data-dry-value', newValue || '');
            this.$refs.hiddenInput.value = newValue || '';
        },
    },
};
</script>
