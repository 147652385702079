<template>
    <div class="input-group">
        <input
            v-bind="attributes"
            ref="input"
        >
        <input
            ref="hiddenInput"
            type="hidden"
            :name="nameForHidden"
            :value="valueForHidden"
        >
        <span
            class="btn btn-outline-secondary btn-icon cursor-pointer"
            @click="clearPicker"
        >
            <i
                v-if="!clearButton"
                class="icon-ic_fluent_calendar_ltr_24_regular"
            />
            <i
                v-if="clearButton"
                class="icon-ic_fluent_dismiss_24_regular"
            />
        </span>

        <span
            v-if="button"
            ref="button"
            v-bind="buttonAttributes"
            @click="clickButton"
            v-html="button.html"
        />
    </div>
</template>

<script>
import SplInputMixin from '../input-mixin';

export default {
    name: 'XInputDatetime',
    mixins: [SplInputMixin],
    props: {
        options: Object,
        datePickerConfig: {
            type: Object,
            default: () => ({}),
        },
        clearButton: {
            type: Boolean,
            default: true,
        },
        value: String,
        button: Object,
    },
    data() {
        return {
        };
    },
    computed: {
        attributes() {
            let options = { ...this.options };
            if (!isset(options, 'class')) {
                options.class = '';
            }
            options.class = this.addClass(options.class, ['form-control', 'input-sm', 'date-picker-single', 'input-date-range']);

            if (isset(options, 'name')) {
                // Name sets to hidden input instead of this
                delete options.name;
            }

            return options;
        },
        buttonAttributes() {
            let options = { ...this.button };

            if (isset(options, 'html')) {
                delete options.html;
            }

            return options;
        },
        nameForHidden() {
            if (isset(this.options, 'name')) {
                return this.options.name;
            }

            return '';
        },
        valueForHidden() {
            if (this.value === '') {
                return '';
            }

            return this.value;
        },
    },
    watch: {
        value(newValue) {
            let currentVal = $(this.$refs.input).val();
            if (newValue != currentVal) {
                if (empty(newValue)) {
                    $(this.$refs.input).val('');
                } else {
                    let val = moment(newValue, 'YYYY-MM-DD HH:mm:ss').format(datetime_format);
                    $(this.$refs.input).val(val).attr('original-value', val);
                }
            }
        },
    },
    mounted() {
        let self = this;
        let config = {};
        $.extend(true, config, daterangepicker_single_datetime_default_config, this.datePickerConfig);

        $(this.$refs.input).customdatetimepicker(config, (start) => {
            let datePickerSingle = $(self.$refs.input);
            datePickerSingle.val(start.format(datetime_format));
            datePickerSingle.trigger('change');
        });

        let defaultValue = '';
        if (!empty(this.value) && this.value !== '0000-00-00 00:00:00') {
            defaultValue = moment(this.value, 'YYYY-MM-DD HH:mm:ss').format(datetime_format);
        }

        $(this.$refs.input)
            .val(defaultValue)
            .attr('original-value', defaultValue)
            .trigger('change')
            .on('apply.daterangepicker', (event, picker) => {
                self.$emit('input', picker.startDate.format('YYYY-MM-DD HH:mm:ss'));
            });

        [$('.vue-dialog-body'), $(window), $('.splynx-dialog-content')].forEach((scrollElement) => {
            $(scrollElement).on('scroll', () => {
                if ($(this.$refs.input) && window.matchMedia('(min-width: 768px)').matches) {
                    $(this.$refs.input).blur();
                    $(this.$refs.input).data('daterangepicker')?.hide();
                }
            });
        });
    },
    methods: {
        clearPicker() {
            $(this.$refs.input).val('').trigger('change');
            this.$emit('input', '');
        },
        clickButton() {
            if (isset(this.button, 'handler') && typeof this.button.handler === 'function') {
                this.button.handler(this._self, this.$refs.input, this.$refs.button);
            }
        },
    },
};
</script>
