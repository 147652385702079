<template>
    <div class="input-wrap">
        <select
            ref="input"
            v-bind="attributes"
        >
            <template v-if="withGroups">
                <optgroup
                    v-for="(group, index) in selectData"
                    :key="index"
                    :label="group.label"
                >
                    <option
                        v-for="(option, optionIndex) in group.children"
                        :key="optionIndex"
                        :value="option.value"
                        v-html="option.text"
                    />
                </optgroup>
            </template>
            <template v-else>
                <option
                    v-for="(option, index) in selectData"
                    :key="index"
                    :value="option.value"
                    v-html="option.text"
                />
            </template>
        </select>
    </div>
</template>

<script>
import { empty } from '@/utils/functions';
import { SELECT_VALUE_ALL } from '@/constants';

export default {
    name: 'XMultipleSelect',
    inheritAttrs: false,
    props: {
        label: String,
        options: Object,
        type: String,
        sortByText: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Object,
            default: () => ({}),
        },
        value: [String, Number, Array],
    },
    data() {
        return {
            multipleSelect: null,
            previousTriggerValue: null,
            initialized: false,
            optionsData: this.options.options,
        };
    },
    computed: {
        attributes() {
            let op = { ...this.$attrs, ...this.options };
            delete op.options;
            op.multiple = 'multiple';
            delete op['data-placeholder'];

            if (!isset(op, 'style')) {
                op.style = 'width: 100%;';
            }

            return op;
        },
        selectData() {
            let rawData = this.optionsData;
            let data = [];

            for (let groupLabel in rawData) {
                let group = rawData[groupLabel];
                let groupData;

                if (typeof group === 'object') {
                    groupData = { type: 'optgroup', label: groupLabel, children: [] };

                    for (let val in group) {
                        if (typeof group[val] === 'object') {
                            group[val] = group[val].title;
                        }

                        let child = {
                            text: group[val],
                            value: val,
                        };

                        groupData.children.push(child);
                    }
                } else {
                    groupData = {
                        text: group,
                        value: groupLabel,
                    };
                }

                data.push(groupData);
            }

            if (this.sortByText) {
                data.sort((a, b) => {
                    a = isset(a, 'label') ? a.label.toUpperCase() : a.text.toUpperCase();
                    b = isset(b, 'label') ? b.label.toUpperCase() : b.text.toUpperCase();

                    if (a === 'ALL' || a === 'ANY' || a === 'ANYONE') {
                        return -1;
                    }
                    if (b === 'ALL' || b === 'ANY' || b === 'ANYONE') {
                        return 1;
                    }

                    return (a > b) ? 1 : -1;
                });

                data.forEach((v) => {
                    if (isset(v, 'children')) {
                        v.children.sort((a, b) => ((a.text.toUpperCase() > b.text.toUpperCase()) ? 1 : -1));
                    }
                });
            }

            return data;
        },
        needCheckAll() {
            return 'data-check-all' in this.attributes && this.attributes['data-check-all'];
        },
        withGroups() {
            let rawData = this.optionsData;
            let group = rawData[Object.keys(rawData)[0]];
            return typeof group === 'object';
        },
    },
    watch: {
        value(newValue, oldValue) {
            if (empty(newValue)) {
                newValue = [];
            }

            if (!Array.isArray(newValue)) {
                if (typeof newValue === 'string') {
                    if (newValue.includes('%2C')) {
                        newValue = decodeURIComponent(newValue);
                    }
                    if (newValue.includes(',')) {
                        newValue = newValue.split(',');
                    } else {
                        newValue = [newValue];
                    }
                }
            }

            if (typeof newValue === 'number') {
                this.$emit('input', newValue);
                return false;
            }

            if (newValue.join(',') !== $(this.$refs.input).val().join(',')) {
                this.setValue(newValue);
            } else if (oldValue === SELECT_VALUE_ALL) {
                this.$emit('input', newValue);
            }
        },
        optionsData: {
            deep: true,
            handler(newOptions, old) {
                if (this.initialized) {
                    let value = null;

                    // Huck for moving new selected value throw event options
                    if (isset(newOptions, ['selectValue'])) {
                        value = newOptions.selectValue;
                        delete newOptions.selectValue;
                    }

                    if (JSON.stringify(newOptions) !== JSON.stringify(old) && (!empty(newOptions) && !empty(old))) {
                        this.$nextTick(() => {
                            $(this.$refs.input).multipleSelect('refresh');
                            this.setValue(value || this.value);
                            let searchInput = $($(this.$refs.input).data('multipleSelect').$searchInput[0]);
                            if (!searchInput?.hasClass('input-ms-search')) {
                                searchInput.addClass('input-ms-search');
                            }
                        });
                    }
                }
            },
        },
    },
    mounted() {
        this.init();

        /*
        You can create customer event for options updating
        Example:
            // Create event object
            let customEvent = new Event('update-options', { bubbles: true });
            // Set options for updating
            customEvent.selectOptions = {'one', 'two'};
            // Here you can set select value, will set after options will changed
            customEvent.selectOptions.selectValue = SELECT_VALUE_ALL;
            // Trigger event for select element
            document.getElementById('admin_scheduling_map_status_filter').dispatchEvent(customEvent);
        */
        this.$refs.input.addEventListener('update-options', (event) => {
            if (!empty(event.selectOptions)) {
                this.optionsData = event.selectOptions;
            }
        });
    },
    methods: {
        init() {
            this.initWidget();
            if (!this.needCheckAll) {
                this.setValue(this.value);
            }

            $(this.$refs.input).off('change');
            $(this.$refs.input).change(() => {
                let newValue = $(this.$refs.input).val();
                if (this.previousTriggerValue !== null && this.previousTriggerValue.join(',') === newValue.join(',')) {
                    return;
                }
                this.previousTriggerValue = newValue;
                this.$emit('input', newValue);
            });
            this.initialized = true;
        },
        setValue(value) {
            if (typeof this.multipleSelect === 'undefined') {
                return;
            }
            if (empty(value)) {
                value = [];
            }

            if (value === SELECT_VALUE_ALL || (Array.isArray(value) && value[0] === SELECT_VALUE_ALL)) {
                this.multipleSelect.checkAll();
                return;
            }

            if (!Array.isArray(value)) {
                if (value.includes('%2C')) {
                    value = decodeURIComponent(value);
                }

                if (value.includes(',')) {
                    value = value.split(',');
                } else {
                    value = [value];
                }
            }
            this.multipleSelect.setSelects(value);
        },
        initWidget() {
            if (!empty($(this.$refs.input).data('multipleSelect'))) {
                // Multiple select already initialized
                return;
            }

            let htmlElement = this.$refs.input;
            let displaySelectedValues = false;
            let countSelected = 5;

            if ($('optgroup', htmlElement).length != undefined && $('optgroup', htmlElement).length > 0) {
                displaySelectedValues = true;
                countSelected = 0;
            }

            let multipleOptions = {
                displayValues: displaySelectedValues,
                minimumCountSelected: countSelected,
                width: htmlElement.style.width,
                filter: true,
                onOpen() {
                    window.fixDropDownPosition(htmlElement);
                    $(window).on('scroll', () => {
                        window.fixDropDownPosition(htmlElement);
                    });

                    let multipleSelect = $(htmlElement).data('multipleSelect');
                    let modal = $(htmlElement).closest('.ui-dialog');
                    let contentWrapper;
                    let dropElement;
                    let modalHeight;
                    let widthDropElement;
                    let heightDropElement;
                    let cssParams = {};

                    if (modal.length > 0) {
                        $('.splynx-dialog-content').css({
                            overflow: 'hidden',
                        });

                        contentWrapper = $(htmlElement).closest('.ui-dialog-content');
                        dropElement = multipleSelect.$drop;
                        modalHeight = $(contentWrapper).height();
                        widthDropElement = $(contentWrapper).find('.ms-choice').outerWidth();
                        dropElement.css({ bottom: 'unset' });
                        heightDropElement = dropElement.height();

                        cssParams = {
                            width: widthDropElement,
                            position: 'fixed',
                            bottom: 'unset',
                        };

                        let msParentTopOffset = $(htmlElement).parent().find('.ms-parent').offset().top;
                        let scrollTop = $(window).scrollTop();
                        let msParentHeight = $(htmlElement).parent().find('.ms-parent').height();

                        if (dropElement.hasClass('top')) {
                            // Position === top
                            cssParams.top = msParentTopOffset - scrollTop - heightDropElement;
                        } else {
                            // Position === bottom
                            cssParams.top = msParentTopOffset - scrollTop + msParentHeight;
                        }

                        if (heightDropElement > 0) {
                            cssParams.height = heightDropElement;
                        }

                        dropElement.css(cssParams);

                        // Calculate current position for multipleSelect
                        let top = $(dropElement).offset().top - $(contentWrapper).offset().top + $(contentWrapper).scrollTop();
                        if (top + $(dropElement).find('ul').height() > modalHeight) {
                            $(contentWrapper).scrollTop(top);
                        }

                        // Fix multipleSelect height
                        $(dropElement).find('ul').css('max-height', `${this.maxHeight}px`);

                        $(document).ready(() => {
                            $(document).off('focusin');
                            $('.ui-dialog ~ .ms-drop').css('height', 'auto');
                            $('.ms-drop .input-ms-search').each(function () {
                                if ($(this).is(':visible')) {
                                    $(this).get(0).focus();
                                }
                            });
                        });
                    }

                    $(htmlElement).next('.ms-parent').find('.ms-choice').addClass('multipleselect-opened');
                    // Focus on search

                    $(window).on('resize', () => {
                        let topOffset = $('.ui-dialog-content .ms-parent').offset()?.top;
                        if (topOffset === null) {
                            topOffset = 0;
                        }
                        let top = topOffset - $(window).scrollTop() + $('.ms-parent').height();
                        if (top) {
                            $(dropElement).css('top', top);
                        }
                        $(dropElement).css('left', $(htmlElement).offset().left);
                        $(dropElement).css('width', $(contentWrapper).find('.ms-choice').width());
                    });
                },
                onClose() {
                    $(window).off('scroll');
                    $('.splynx-dialog-content').css({
                        'overflow-x': 'hidden',
                        'overflow-y': 'auto',
                    });
                    $(htmlElement).next('.ms-parent').find('.ms-choice').removeClass('multipleselect-opened');
                },
                onClick: this.initChangeMultipleSelectEvent,
                onCheckAll: this.initChangeMultipleSelectEvent,
                onUncheckAll: this.initChangeMultipleSelectEvent,
                ...this.config,
            };

            $(htmlElement).multipleSelect(multipleOptions);
            $(htmlElement).next('.ms-parent').find('.ms-search input').addClass('input-ms-search');
            this.multipleSelect = $(htmlElement).data('multipleSelect');

            // if there is a read-only attribute, we disable the selection
            if ($(htmlElement).attr('readonly')) {
                $(htmlElement).multipleSelect('disable');
            }

            if (this.needCheckAll) {
                this.multipleSelect.checkAll();
            }
        },
        initChangeMultipleSelectEvent() {
            let event = document.createEvent('CustomEvent');
            event.initEvent('change-multiple-select', true, true);
            event.eventName = 'change-multiple-select';
            event.multipleSelectSelectedValues = $(this.$refs.input).val();
            this.$refs.input.dispatchEvent(event);
        },
    },
};
</script>
