<template>
    <div :class="{'input-group': true, 'input-group-file': true, 'input-group-download-file': downloadButton}">
        <span class="btn btn-outline-secondary">
            {{ getButtonText }}
        </span>
        <input
            ref="input"
            type="file"
            :multiple="multiple"
            class="form-control input-sm"
            v-bind="options"
            @change.prevent.stop="inputHandler"
        >
        <input
            type="text"
            class="form-control input-sm"
            :readonly="!customPlaceholder"
            :placeholder="customPlaceholder"
            :value="internalFileName"
        >
        <span
            v-if="downloadButton"
            class="btn btn-outline-secondary btn-icon"
            :title="downloadButtonTitle"
            @click="downloadButtonHandler"
        >
            <i class="icon-ic_fluent_arrow_download_24_regular" />
        </span>
        <slot name="button" />
    </div>
</template>

<script>
import { empty } from '@/utils/functions';

export default {
    name: 'XInputFile',
    props: {
        value: [File, String, FileList],
        options: Object,
        buttonText: {
            type: String,
            default: '',
        },
        fileName: {
            type: String,
        },

        downloadButton: {
            type: Boolean,
            default: false,
        },
        downloadButtonTitle: {
            type: String,
            default: '',
        },
        downloadButtonUrl: {
            type: String,
            default: '',
        },
        customPlaceholder: {
            type: String,
            default: '',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            internalFileName: this.fileName,
        };
    },
    methods: {
        getFilesNames(files) {
            const messageOther = files.length - 2 === 1 ? this.$t('common', 'other') : this.$t('common', 'others');
            files = Array.from(files).map((file, index) => {
                if (index > 2) {
                    return null;
                }
                if (index === 2) {
                    return `+ ${files.length - 2} ${messageOther}`;
                }
                return file.name;
            });
            files = files.filter((file) => !!file);
            return files.join(', ');
        },
        inputHandler(event) {
            this.internalFileName = event.target.files.length > 0 ? event.target.files[0].name : '';
            if (this.multiple) {
                this.internalFileName = event.target.files.length > 0 ? this.getFilesNames(event.target.files) : '';
                this.$emit('input', event.target.files);
                return;
            }
            this.$emit('input', event.target.files[0]);
        },
        downloadButtonHandler() {
            download_by_iframe(this.downloadButtonUrl);
        },
    },
    watch: {
        fileName() {
            this.internalFileName = this.fileName;
        },
    },
    computed: {
        getButtonText() {
            return !empty(this.buttonText) ? this.buttonText : this.$t('common', 'Browse');
        },
    },
};
</script>
