<template>
    <div
        v-if="loading"
        :id="contentId"
        class="content clearfix active"
    >
        <x-preloader-block :loading="loading" />
    </div>
    <div
        v-else
        :id="contentId"
        class="content clearfix active"
        v-html="html"
    />
</template>

<script>
import { empty } from '@/utils/functions';
import { mapMutations } from 'vuex';
import { SET_PAGE_TITLE } from '@/apps/admin/store/mutation-types';
import XPreloaderBlock from '@/components/common/XPreloaderBlock/XPreloaderBlock';

export default {
    name: 'XTwigPage',
    components: { XPreloaderBlock },
    data() {
        return {
            loading: true,
            rawHtml: '',
            html: '',
            contentId: '',
            active: true,
            title: '',
        };
    },
    mounted() {
        splynx_event_bus.reinitEvent('before_switch_page', () => {
            window.closeAllAddonDialogs();
            window.closeLastDialog();
        });

        splynx_event_bus.on('reload-page-content', this.reloadPage);
        this.contentId = this.generateContentId(this.$route.path);
        this.loadPage();
    },
    // Calls when <keep-alive> cache this component
    deactivated() {
        splynx_event_bus.off('reload-page-content', this.reloadPage);
        this.active = false;
    },
    // Calls when <keep-alive> taking this component from cache
    activated() {
        splynx_event_bus.on('reload-page-content', this.reloadPage);
        splynx_event_bus.emit('page-activated');
        this.active = true;
        this.setPageTitle();
    },
    beforeDestroy() {
        splynx_event_bus.off('reload-page-content', this.reloadPage);
    },
    methods: {
        ...mapMutations([SET_PAGE_TITLE]),
        getUrlForLoadPage() {
            let { path } = this.$route;
            if (!empty(this.$route.query)) {
                path += `?${$.param(this.$route.query)}`;
            }
            return path;
        },
        loadPage() {
            if (!this.active) {
                return;
            }
            this.loading = true;
            $.ajax({
                url: this.getUrlForLoadPage(),
                method: 'GET',
                success: (rawHtml) => {
                    this.rawHtml = rawHtml;
                    this.parseHtml(rawHtml);
                    this.$nextTick(() => {
                        splynx_event_bus.emit('after_switch_page');
                    });
                },
                error: () => {
                    this.loading = false;
                },
            });
        },
        parseHtml(html) {
            $(document).trigger('switch_page', true);
            removeWebUiPopovers();
            let root = $('<div>');
            root.append(html);
            let title = $(root).find('title');
            if (title.length > 0) {
                this.title = title.text();
                this.setPageTitle();
                title.remove();
            }

            let scripts = root.find('script');
            root.find('script').remove();

            this.html = root.html();
            this.loading = false;

            this.$nextTick(() => {
                scripts.each((index, script) => {
                    if (script.id) {
                        if ($(`#${script.id}`).length === 0) {
                            $('body').append(script);
                        }
                    } else {
                        $('body').append(script);
                    }
                });

                this.makeBreadcrumbsIcons();
                this.initWidgets();

                // Scrolling page to anchor after render main content
                let { hash } = window.location;
                let element = hash ? document.querySelector(hash) : null;
                let elementOffset = typeof (element) != 'undefined' && element != null ? element.offsetTop - 10 : 0;
                window.scroll({
                    top: elementOffset,
                    behavior: 'smooth',
                });
            });
        },
        reloadPage() {
            this.html = '';
            this.loadPage();
        },
        setPageTitle() {
            this.SET_PAGE_TITLE(this.title);
        },
        makeBreadcrumbsIcons() {
            let icon; let color; let
                rootMenuItem = document.querySelector('.menu-item-level-1.parent-active-item, .menu-item-level-1.active-item');
            if (rootMenuItem) {
                icon = rootMenuItem.querySelector('.main-icon i').className;
                let colorClassName = rootMenuItem.className;
                color = colorClassName.split(' ').pop().slice(6);
            } else {
                icon = this.$route.meta.icon;
                color = this.$route.meta.color;
            }

            $('.splynx-breadcrumbs>span').find('i').remove();
            $('.splynx-breadcrumbs>span').addClass(`bg-${color}`).append(`<i class="${icon}"></i>`);
        },
        initWidgets() {
            window.initWidgets();
            $(document).trigger('after_switch_page', true);
        },
        generateContentId(id) {
            if (id.indexOf('#') !== -1) {
                id = id.substring(0, id.indexOf('#'));
            }

            let urls = [
                'view',
                'edit',
                'admin/config/additional-fields',
                'admin/tariffs/fup',
                'admin/inventory/items',
                'admin/crm/leads/add',
                'admin/tickets/create',
                'admin/tickets/opened',
                'admin/scheduling/tasks--add',
                'admin/scheduling/tasks',
                'admin/tariffs/internet--change-tariff-on-services',
                'admin/tariffs/voice--change-tariff-on-services',
                'admin/tariffs/recurring--change-tariff-on-services',
                'admin/crm/leads/convertation',
                'admin/crm/leads/add',
                'admin/crm/leads/list',
                'admin/customers/add',
                'admin/finance/invoices',
                '/admin/inventory/products',
            ];

            $.each(urls, (i, value) => {
                if (id.indexOf(value) != -1 && id.indexOf('?') != -1) {
                    id = id.substring(0, id.indexOf('?'));
                    return '';
                }
            });

            // Trim last slash
            if (id[id.length - 1] === '/') {
                id = id.slice(0, -1);
            }

            return `content${id.replace(/[^a-z0-9 ,.!]/ig, '_')}`;
        },
    },
    watch: {
        '$route.fullPath': function () {
            if (!this.active) {
                return;
            }
            this.loadPage();
            this.contentId = this.generateContentId(this.$route.path);
        },
    },
};
</script>
