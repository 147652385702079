<template>
    <div
        v-if="sidebarState"
        class="sidebar-wrapper"
        @click="hideOpenedSidebar"
    >
        <transition name="slide-fade">
            <div
                class="sidebar"
                @click.stop=""
            >
                <button
                    type="button"
                    class="btn-close btn-icon-sm"
                    @click="hideOpenedSidebar()"
                />
                <div class="sidebar-header">
                    <h2 v-text="sidebarOptions.title" />
                    <p v-text="sidebarOptions.subtitle" />
                </div>

                <div class="sidebar-content">
                    <component
                        :is="sidebarOptions.component"
                        v-bind="sidebarOptions.props"
                    />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

/**
     * @see See [Confluence](http://docs.oth.spl/confluence/display/SPLYN/XSidebar)
     */
export default {
    name: 'XSidebar',
    mounted() {
        window.addEventListener('keydown', this.processKeyboard);
    },
    destroyed() {
        window.removeEventListener('keydown', this.processKeyboard);
    },
    methods: {
        ...mapActions('sidebar', [
            'hideSidebar',
        ]),
        hideOpenedSidebar() {
            this.hideSidebar();
        },
        processKeyboard(e) {
            if (e.keyCode === 27) {
                this.hideOpenedSidebar();
            }
        },
    },
    computed: {
        ...mapGetters('sidebar', [
            'sidebarState',
            'sidebarOptions',
        ]),
    },
};
</script>
